import { languages } from '~/messages/languages'
import messages from '~/messages/messages'
import type { BasicKeyValue } from './utils/types'
import { makeDateTimeFormat, makeNumberFormat } from './utils/functions'

export default defineI18nConfig(() => {
  const currentLocale = useCurrentLocale()

  const numberFormats: BasicKeyValue = {}
  const datetimeFormats: BasicKeyValue = {}

  languages.forEach((language: string) => {
    // @ts-ignore
    numberFormats[language] = makeNumberFormat(
      // @ts-ignore
      messages[language]['language.currency'] || 'EUR',
    )

    datetimeFormats[language] = makeDateTimeFormat()
  })

  return {
    legacy: false,
    locale: currentLocale,
    fallbackLocale: 'en',
    messages: messages,
    numberFormats: numberFormats,
    datetimeFormats: datetimeFormats,
  }
})
